<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "kloserAccount",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "1.2 Create a Kloser Account",
          description: [
            {
              text: "You can use your mobile number and email to create an account.",
              imgType: false,
            },
            {
              text: "1. Enter your email and mobile number and tap Continue",
              imgType: true,
              img: "4.Sign up-Filled.png",
            },
            {
              text: "2. You will then be asked to enter a one-time password (OTP). This will be delivered via SMS to the mobile number you provided.",
              imgType: true,
              img: "7.OTP Verification-1.png",
            },
            {
              text: "3. Enter the 6-digit code received and tap Continue\n",
              imgType: true,
              img: "7.OTP Verification – 3.png",
            },
            {
              text: "4. Enter your profile details and tap Submit to complete the your profile ",
              imgType: true,
              img: "8.Complete profile-2.png",
            },
            {
              text: "5. Set your 6-digit PIN for login and transaction verification",
              imgType: true,
              img: "9.Set PIN – 2.png",
            },
            {
              text: "6. Congratulations! You have successfully signed up for Kloser",
              imgType: true,
              img: "10.Completed!.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
